const ESchemaLaporan = Object.freeze([
  {
    column: "No",
    type: String,
    value: (item) => item.no,
  },
  {
    column: "Nama",
    type: String,
    width: 40,
    value: (item) => item.description,
  },
  {
    column: "NIP",
    type: String,
    width: 20,
    value: (item) => item.nip,
  },
  {
    column: "Bidang",
    type: String,
    width: 80,
    value: (item) => item.fdivision,
  },
  {
    column: "No Urut SKKP",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
  {
    column: "JFT",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
  {
    column: "JFU",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
  {
    column: "Struktural",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
  {
    column: "Penyesuaian Ijazah",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
  {
    column: "Keterangan",
    type: String,
    width: 40,
    value: (item) => item.none,
  },
]);

export default ESchemaLaporan;
