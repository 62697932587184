<template>
  <v-card class="elevation-2 ma-2">
    <v-card-title>
      <v-icon color="success" class="mr-2">mdi-download</v-icon> {{ title }}
      <v-spacer></v-spacer>
    </v-card-title>

    <v-expand-transition>
      <v-card
        class="ml-2 mr-2 elevation-0 grey lighten-4"
        v-show="showFilter === true"
      >
        <v-card-text>
          <v-row align="baseline">
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                outlined
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                label="Dinas/OPD"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="filterFtemplateReg"
                :items="itemsFTemplateReg"
                item-value="id"
                item-text="description"
                dense
                outlined
                chips
                small-chips
                deletable-chips
                clearable
                label="Jenis Registrasi"
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="filterStatusUsulan"
                :items="itemsStatusUsulan"
                item-value="id"
                item-text="description"
                dense
                outlined
                chips
                small-chips
                deletable-chips
                clearable
                label="Status Usulan"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="menuTrDateFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="trDateFrom"
                    label="Tanggal Awal"
                    append-icon="mdi-calendar"
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="trDateFrom"
                  @input="menuTrDateFrom = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="menuTrDateTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="trDateTo"
                    label="Tanggal Akhir"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="trDateTo"
                  @input="menuTrDateTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" sm="4" md="2">
              <v-btn
                @click="exportToPdf"
                color="red lighten-1 white--text"
                small
                block
              >
                Export As PDF
                <v-icon small class="ml-1" color="white"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <v-btn @click="exportToExcel" color="success" small block>
                Export As Excel
                <v-icon small class="ml-1" color="white"
                  >mdi-microsoft-excel</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
    
    <script>
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import FtRegistrasi from "@/models/ft-registrasi";
import FtRegistrasiFilter from "@/models/payload/ft-registrasi-filter";
import writeXlsxFile from "write-excel-file";
import FileSaver from "file-saver";
import ESchemaLaporan from "@/models/e-schema-laporan";

export default {
  name: "Laporan",
  components: {},
  data() {
    return {
      title: "UNDUH LAPORAN",
      snackbar: false,
      snackBarMesage: "",
      trDateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      trDateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuTrDateFrom: false,
      menuTrDateTo: false,
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9999,
      pageSizes: [10, 25, 50, 150, 500, 1500],
      itemsMonth: [
        { id: 1, description: "Januari" },
        { id: 2, description: "Februari" },
        { id: 3, description: "Maret" },
        { id: 4, description: "April" },
        { id: 5, description: "Mei" },
        { id: 6, description: "Juni" },
        { id: 7, description: "Juli" },
        { id: 8, description: "Agustus" },
        { id: 9, description: "September" },
        { id: 10, description: "Oktober" },
        { id: 11, description: "November" },
        { id: 12, description: "Desember" },
      ],

      showFilter: true,
      filterFdivisions: [],
      filterFtemplateReg: [],
      filterFareaBean: [],

      filterStatusUsulan: 3,
      itemsStatusUsulan: [
        { id: 1, description: "1. Menunggu Verifikasi" },
        { id: 2, description: "2. Proses Verifikasi" },
        { id: 3, description: "3. Terverifikasi/Valid" },
        { id: 4, description: "4. Dikembalikan/Tidak Valid" },
        { id: 5, description: "5. Approve" },
      ],
      ftRegistrasi: [new FtRegistrasi(0, "", "")],
      // itemsFDivision: [{ id: 0, kode1: "", description: "" }],
    };
  },
  watch: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FtRegistrasiModule/updateItemsFDivision", value);
      },
    },
    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
  },
  methods: {
    runExtendedFilter(action) {
      const extendedFilter = new FtRegistrasiFilter();

      if (this.filterFdivisions.length > 0) {
        extendedFilter.fdivisionIds = this.filterFdivisions;
      } else {
        extendedFilter.fdivisionIds = this.filterFdivisions.map(
          (item) => item.id
        );
      }

      extendedFilter.ftemplateRegIds = this.filterFtemplateReg;
      extendedFilter.validasiStatusIds = [];
      extendedFilter.fdivisionIds = this.filterFdivisions;
      if (this.filterStatusUsulan) {
        extendedFilter.statusUsulanBean = this.filterStatusUsulan;
      }

      extendedFilter.pageNo = this.currentPage;
      extendedFilter.pageSize = this.pageSize;
      extendedFilter.sortBy = "id";
      extendedFilter.order = "DESC";
      extendedFilter.search = this.search;
      extendedFilter.trDateFrom = this.trDateFrom;
      extendedFilter.trDateTo = this.trDateTo;
      extendedFilter.submittedOnly = true;

      FtRegistrasiService.getPostAllFtRegistrasiContainingExt(
        extendedFilter
      ).then(
        (response) => {
          const { items, totalPages } = response.data;
          this.ftRegistrasi = items;
          this.totalPaginationPages = totalPages;
          if (this.ftRegistrasi.length === 0) {
            this.snackbar = true;
            this.snackBarMesage = "Tidak Ada Data Yang Bisa Diunduh";
          } else {
            //EXPORT PDF
            if (action === "pdf") {
              console.log("unduh laporan PDF");
              //API PDF
            }
            //EXPORT EXCEL
            else if (action === "excel") {
              let listItemsSourceSorted = this.ftRegistrasi
                .slice()
                .sort(function (a, b) {
                  return a.nip > b.nip ? 1 : -1;
                });
              let itemsReports = [];
              listItemsSourceSorted.forEach((item, index) => {
                let newItem = item;

                newItem.no = `${index + 1}`;
                newItem.description =
                  item.description === "" ? "-" : item.description;
                newItem.nip = item.nip === "" ? "-" : item.nip;
                newItem.fdivision =
                  item.fdivisionBean === 0
                    ? "-"
                    : `${this.lookupFDivision(item.fdivisionBean).description}`;
                itemsReports.push(newItem);
              });
              // console.log(itemsReports)
              this.exportItemsToFile(itemsReports);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    fetchParent() {
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
            // console.log(response.data.items);
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            // console.log(JSON.stringify(response.data));
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      FTemplateRegService.getAllFTemplateReg().then(
        (response) => {
          // console.log(JSON.stringify(response.data))
          this.itemsFTemplateReg = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    fetchFtRegistrasi() {
      if (this.showFilter) {
        this.runExtendedFilter();
      } else {
        FtRegistrasiService.getAllFtRegistrasiContaining(
          this.currentPage,
          this.pageSize,
          "id",
          "DESC",
          this.search
        ).then(
          (response) => {
            const { items, totalPages } = response.data;
            this.ftRegistrasi = items;
            this.totalPaginationPages = totalPages;
            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          (error) => {
            console.log(error.response);
          }
        );
      }
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    exportToPdf() {
      this.runExtendedFilter("pdf");
    },
    exportToExcel() {
      this.runExtendedFilter("excel");
    },
    exportItemsToFile(listItemsSource) {
      let fileBits = `rekap_data_laporan.xlsx`;
      let schema = ESchemaLaporan;
      writeXlsxFile(listItemsSource, {
        schema,
        headerStyle: {
          backgroundColor: "#e6cc00",
          fontWeight: "bold",
          align: "center",
        },
        fileName: fileBits,
      });

      let file = new File(fileBits, `rekap_data_laporan.txt`, {
        type: "xlsx/plain;charset=utf-8",
      });
      FileSaver.saveAs(file);
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent();
      this.fetchFtRegistrasi();
    }
  },
};
</script>
    <style scoped>
</style>